import React, { useRef, useState } from "react";
import styled from '@emotion/styled'
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { usePrisContext } from "../../context/AppContext";
import PaymentApi from "../../api/paymentApi";
import SaleConfirmApi from "../../api/saleConfirmApi";
import MainApi from "../../api/mainApi";
import GetcustomerId from "../../api/getCustomerId";
import { getAllByAltText } from "@testing-library/react";

function PaymentStep({ handleNextPrevClick }) {
  const regRef = useRef();
  const accountRef = useRef();
  const acceptRef = useRef();
  const [date1, setDate] = useState(new Date());
  const { contextDavaData } = usePrisContext();
  const [PaymentMethod, setPaymentMethod] = useState('add-betalingsservice');
  const [registerNumber, setregisterNumber] = useState('');
  const [Account, setAccount] = useState('');
  const [ErrorMSg, setErrorMSg] = useState('');
  const [Loaderkit, setLoaderkit] = useState(false);
  const [TermAccept, setTermAccept] = useState(false);

  const [items, setItems] = useState([]);


  const onSubmitHandler = async (e) => {

    if (!registerNumber && registerNumber === '' && PaymentMethod === 'add-betalingsservice') {
      regRef.current.focus();
    } else if (!Account && Account === '' && PaymentMethod === 'add-betalingsservice') {
      accountRef.current.focus();
    } else if (!TermAccept) {
      acceptRef.current.focus();
    } else {
      setLoaderkit(true);

      var paymentInfo = {};
      if (PaymentMethod === 'add-mobilepay') {
        paymentInfo = {
          "register_subscription": true,
          "origin": "api/app",
          "mobile_phone_number": contextDavaData.phone
        }
      } else if (PaymentMethod === 'add-betalingsservice') {
        paymentInfo = {
          cpr: contextDavaData.CPRNumber,
          reg_number: registerNumber,
          account_number: Account
        }
      } else {
        setErrorMSg({ msg: 'Please choose payment method', type: 'error' });
        setLoaderkit(false);
      }
      const payload = await PaymentApi(paymentInfo, `${process.env.REACT_APP_API_BASE_URL}api/sale/v3/${contextDavaData.externalId}/subscriptions/${PaymentMethod}`, `${contextDavaData?.AuthToken}`);
      if (payload.status == 201) {
        if (PaymentMethod === 'add-mobilepay') {
          handleProductionApi(payload?.data?.user_redirect);
        } else {
          saleConfirmApi();
        }
      } else {
        setErrorMSg({ msg: 'Something went wrong, please try again', type: 'error' });
        setLoaderkit(false);
      }
      setTimeout(() => {
        setErrorMSg('');
      }, 5000);

    }
  }

  // production check box function
  async function handleProductionApi(redirect_url) {
    var productBundleCode = '';
    var productBundleName = '';
    if (contextDavaData.davadata[0].data.postnr >= 5000) {
      if (contextDavaData.Area === 'FAIR Flex') {
        productBundleName = `Fairpris FLEX PRODUKTION DK1`;
        productBundleCode = 'PP_11';
      } else if (contextDavaData.Area === 'FAIR Produktion') {
        productBundleName = `${contextDavaData.Area} DK1`;
        productBundleCode = 'PP_11';
      } else {
        productBundleName = `Fairpris FAST PRODUKTION DK1`;
        productBundleCode = 'PP_9';
      }
    } else {
      if (contextDavaData.Area === 'FAIR Flex') {
        productBundleName = `Fairpris FLEX PRODUKTION DK2`;
        productBundleCode = 'PP_12';
      } else if (contextDavaData.Area === 'FAIR Produktion') {
        productBundleName = `${contextDavaData.Area} DK2`;
        productBundleCode = 'PP_12';
      } else {
        productBundleName = `Fairpris FAST PRODUKTION DK2`;
        productBundleCode = 'PP_10';
      }
    }
   var start_date = '';
  
  if (contextDavaData.Area === 'FAIR Produktion') {
		start_date = contextDavaData.date;
  }else{
  	if(contextDavaData.PowerDelivered==='SKIFT til'){
		if(contextDavaData.exAgreement ==='Nej'){
			start_date = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate()}`; 
		}else{
			start_date = contextDavaData.date;
		}
	}else{
		start_date = contextDavaData.date;
	}
  }
/*
  if (contextDavaData.Area === 'FAIR Produktion') {
	  if (contextDavaData.exAgreement === 'Nej') {
		  //current date
      start_date = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate()}`;
    } else {
		if(date1.getDate() <= 14){
			// just next month 1st date
			start_date = `${date1.getFullYear()}-${(date1.getMonth() + 2).toString().padStart(2, '0')}-01`;
		} else{
			// 1st date after 2 months
			start_date = `${date1.getFullYear()}-${(date1.getMonth() + 3).toString().padStart(2, '0')}-01`;
		}
    }  
  }else{	  
	  if (contextDavaData.ProfitStream === 'profit_stream') {
		if (contextDavaData.exAgreement === 'Nej') {
		  start_date = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate()}`;
		} else {
		  start_date = contextDavaData.date;
		}
	   } else {
		start_date = contextDavaData.date;
		} 
  } 
*/
    const productionSaleOptions = {
      "accept": true,
      "source": "signup",
      //"product_bundle_code": productBundleCode,
      "cpr": contextDavaData.CPRNumber,
      "agreement_creation_type": (contextDavaData.Area==='FAIR Produktion')?"new":"use_last",
      "send_invoice_on_email": true,
      "address": {
        "dar_reference": contextDavaData.davadata[0].data.id,
        "first_name": contextDavaData?.firstName,
        "last_name": contextDavaData?.lastName,
        "phone": contextDavaData?.phone,
        "email": contextDavaData?.Email,
        "street_name": contextDavaData.davadata[0].data.vejnavn,
        "street_no": contextDavaData.davadata[0].data.husnr,
        "floor": contextDavaData.davadata[0].data.etage,
        "side": contextDavaData.davadata[0].data.dør,
        "zip_code": contextDavaData.davadata[0].data.postnr,
        "city": contextDavaData.davadata[0].data.postnrnavn
      },
      "delivery": {
        "identifier": contextDavaData.GSRNNumber,
        "type": 'electrical',//contextDavaData.Area === 'FAIR Produktion' ? "production" : "electrical",
        "creation_type": contextDavaData.PowerDelivered === 'SKIFT til' && contextDavaData.exAgreement === 'Ja' ? 'change_of_supplier' : 'move_in',
        "start_date": start_date,
        "metering_point_type": contextDavaData.Area === 'FAIR Produktion' ? "E18" : "E17"
      },
      "product_timeline": [
        {
          "product_bundle_code": productBundleCode
        }
      ]
    }
    if (contextDavaData.Area == 'FAIR Produktion') {
      productionSaleOptions.delivery.tags = ["Produktion"];
	  productionSaleOptions.payment_subscription =  {"method": "choose-later"};
    }
    if (redirect_url != '') {
      localStorage.setItem('items', JSON.stringify({ 'ProfitStream': contextDavaData.ProfitStream, 'externalId': contextDavaData.externalId, 'AuthToken': contextDavaData?.AuthToken, 'saleData': productionSaleOptions }));

      window.location.href = redirect_url;
      //window.location.href='/payment-success';
    } else {
      // sale v3 api
      var payloadSale = await MainApi(productionSaleOptions, `${process.env.REACT_APP_API_BASE_URL}api/sale/v4`, `${contextDavaData?.AuthToken}`);
      if (payloadSale.status == 200) {
        window.location.href = 'https://fairpris.dk/el/tak-for-at-blive-el-kunde/';
      } else {
        setErrorMSg({ msg: 'Something went wrong, please try again', type: 'error' });
      }
    }
  }

  // Sale confirm ation API call function
  async function saleConfirmApi() {
    const payloadConfirm = await SaleConfirmApi([], `${process.env.REACT_APP_API_BASE_URL}api/sale/v3/${contextDavaData.externalId}/confirm`, `${contextDavaData?.AuthToken}`);
    if (payloadConfirm?.status === 200) {
      if (contextDavaData.Area === 'FAIR Produktion') {
        let estimateInfo = {
          "manual_estimated_usage": 0
        }
        var updateManualEstimated = await MainApi(estimateInfo, `${process.env.REACT_APP_API_BASE_URL}api/electrical/v2/${contextDavaData.externalId}/update-manual-estimated-usage`, `${contextDavaData?.AuthToken}`);
        console.log('estimated-usage: ',updateManualEstimated);
      }
      if (contextDavaData.ProfitStream && contextDavaData.ProfitStream !== undefined) {
        handleProductionApi('');
      } else {
        window.location.href = 'https://fairpris.dk/el/tak-for-at-blive-el-kunde/';
      }
      setErrorMSg({ msg: payloadConfirm?.data?.message, type: 'success' });
      setLoaderkit(false);
      let paymentdata = {
        method: PaymentMethod,
        registerNo: registerNumber,
        account: Account
      }
      //handleNextPrevClick('payment', paymentdata)
    } else {
      setErrorMSg({ msg: 'Something went wrong, please try again', type: 'error' });
      setLoaderkit(false);
    }
  }
  return (
    <StepContainer md="12">
      <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            borderRadius: '10px',
          }}
        >
          <FlexColumnContainer className={`px-csm-0 p-0`}>
            <div
              style={{
                textAlign: 'center',
                padding: '0px 0 20px 0',
                fontWeight: 'bold',
              }}
            >
              <span className="roboto-bold">Vælg den betalingsmetode du foretrækker </span>
            </div>
            <Row xs={1} sm={1} md={2} lg={3} xl={3} className="g-4 col-lg-12 col-12">
              <Col className={`px-csm-0`}>
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="mr-35 card__title"><strong>Betalings Service </strong></Card.Title>
                    <input type="radio" name="payment_method" className="area_radio position-absolute opacity-0" value={'add-betalingsservice'} onChange={(e) => setPaymentMethod(e.target.value)} defaultChecked />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
                      <li className="mb-0 inter-font list-style-none p-0 f-75">Vælg denne mulighed hvis du vil betale via betalingsservice</li>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              <Col className={`px-csm-0`}>
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="text-left mr-35 card__title"><strong>MobilePay </strong></Card.Title>
                    <input type="radio" name="payment_method" className="area_radio position-absolute opacity-0" onChange={(e) => setPaymentMethod(e.target.value)} value={'add-mobilepay'} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
                      <li className="mb-0 inter-font list-style-none p-0 f-75">Hvis du vil betale med MobilePay Subscriptions</li>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col>
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="text-left mr-35"><strong>Kortbetaling </strong></Card.Title>
                    <input type="radio" name="payment_method" className="area_radio position-absolute opacity-0" onChange={(e) => setPaymentMethod(e.target.value)} value={'add-epay'} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
                      <p>Vælg denne mulighed hvis du vil betale via betalingsservice </p>
                    </Col>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
            {PaymentMethod === 'add-betalingsservice' ?
              <Row md={1} className="mt-4 col-lg-12 col-12 justify-column text-start">
                <Col className="mb-2">
                  <b className="roboto-bold f-75">Indtast venligst dit registrerings- og kontonummer</b>
                </Col>
                <Col className="d-inline-block col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0">
                  <Form.Control
                    ref={regRef}
                    type="text"
                    name="registreringsnummer"
                    placeholder="Registreringsnummer"
                    className="mb-3 mb-md-0 mb-lg-0"
                    onChange={(e) => setregisterNumber(e.target.value)}
                  />
                </Col>
                <Col className="d-inline-block col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0">
                  <Form.Control
                    ref={accountRef}
                    type="text"
                    name="konto"
                    placeholder="Kontonummer"
                    onChange={(e) => setAccount(e.target.value)}
                  />
                </Col>
              </Row>
              : null}
            <Row md={1} className="mt-4 col-lg-12 col-12 justify-column text-start">
              <Col className="d-flex col-12 px-csm-0">
                <Form.Check
                  ref={acceptRef}
                  inline
                  label={``}
                  name="accept"
                  type={'checkbox'}
                  id={`inline-checkbox-2`}
                  className="agreement_check mx-0"
                  onChange={(e) => setTermAccept(!TermAccept)}
                /><label htmlFor="inline-checkbox-2" className="inline inter-font f-75" style={{ userSelect: 'none' }}>Ved at klikke "Bliv kunde" bekræfter jeg, at jeg accepterer at oprette en strømaftale hos FairPris ApS. Jeg er indforstået med, at FairPris ApS vil informere mit nuværende selskab om flytningen på mine vegne, og jeg accepterer samtidig FairPris ApS' <a href="https://fairpris.dk/el/handelsbetingelser/" target="_blank" style={{ color: 'var(--bs-body-color)' }}>handelsbetingelser.</a></label>
              </Col>
              {ErrorMSg ?
                <Col className="mt-5 text-center px-csm-0">
                  <p className={`alert alert-${ErrorMSg.type === 'success' ? 'success' : 'danger'}`}>{ErrorMSg ? ErrorMSg?.msg : null}</p>
                </Col>
                : null}
            </Row>
            <FlexColumnContainer className="col-lg-10 col-12 px-csm-0">
              <div className="col-md-12 col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                {/* <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5 border-2"
                  outline
                  onClick={() => handleNextPrevClick(5, [])}
                >
                  Tilbage
                </CDBBtn> */}
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5"
                  onClick={() => onSubmitHandler()}
                >
                  {Loaderkit ?
                    <img src="../images/loader.gif" style={{ width: '20px', marginRight: '10px' }} />
                    : null}
                  Bliv kunde
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default PaymentStep;
