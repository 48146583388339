import { useEffect, useState } from 'react';
 import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import { usePrisContext } from "./context/AppContext";
import MainApi from "./api/mainApi";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

export default function PaymentFail(props) {
	const [cookies, setCookie] = useCookies(['externalId']);
	const { contextDavaData } = usePrisContext();
  const [Loaderkit, setLoaderkit] = useState(false);
 
   useEffect(() => {
		async function hitApi() {
			var productionSaleOptions = cookies.sale_data;
			var AuthToken = cookies.AuthToken;
			var externalId = cookies.externalId;
		}
		hitApi();
	}, []);

    return (
        <Row className=''>
            <Col md={12} className="d-flex flex-column justify-content-between">
                 <p>Vi beklager, men der opstod en fejl under oprettelsen af din betalingsaftale via MobilePay.

Det kan være, at du enten har glemt at acceptere betalingsaftalen, eller at den blev annulleret i processen.

Vi opfordrer dig til at kontrollere din MobilePay-app for at sikre, at betalingsaftalen er blevet accepteret korrekt. Hvis den ikke vises, bedes du genstarte processen for at oprette en ny betalingsaftale.

Hvis du fortsat oplever problemer, er du velkommen til at kontakte vores kundeservice på tlf 
+45 24 460 460, og vi vil med glæde hjælpe dig med at løse eventuelle problemer.

Vi beklager ulejligheden og takker for din forståelse.</p>
             </Col>
        </Row>
    );
}
