import axios from "axios";

const PaymentApi = async (params = [], url = '', token = '') => {

	var parameters = {
		method: 'post',
		url: url,
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token ? token : process.env.REACT_APP_TOKEN}`,
		},
		data: params
	};
	
	try {
		var response = await axios(parameters);
		return response;
	} catch (error) {
		return error;
	}
	
};

export default PaymentApi;