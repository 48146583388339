import axios from "axios";

const AuthToken = async (params = [], url = '', method = 'post') => {
    const parameterss = {
        method: method,
        url: `${process.env.REACT_APP_API_BASE_URL}v1/oauth/token`,
        data: {
            "grant_type": `${process.env.REACT_APP_GRANT_TYPE}`,
            "client_id": `${process.env.REACT_APP_CLIENT_ID}`,
            "client_secret": `${process.env.REACT_APP_CLIENT_SECRET}`
        }
    };

    try {
        const response = await axios(parameterss);
        return response;
    } catch (error) {
        return error;
    }

};

export default AuthToken;