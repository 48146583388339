import React, { useEffect, useRef, useState, useContext } from "react";
import styled from '@emotion/styled'
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { usePrisContext } from "../../context/AppContext";
import DavaKeyApi from "../../api/getDavakeyApi";
import DatePicker from "react-datepicker";
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import da from 'date-fns/locale/da';
import AuthApi from "../../api/authApi";
registerLocale('da', da);

function CPRCheckStep({ handleNextPrevClick }) {
  const { contextDavaData } = usePrisContext();
  const addressRef = useRef(null);
  const cprRef = useRef(null);
  const passRef = useRef(null);

  const [CPRNumber, setCPRNumber] = useState(contextDavaData.CPRNumber);
  const [Loaderkit, setLoaderkit] = useState(false);
  const [CPRRight, setCPRRight] = useState(false);
  const [CPRWrong, setCPRWrong] = useState(false);

  // username and password
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  var data = {
    cpr: CPRNumber,
    infoType: 7
  }
 

  const handleNextClick = async () => {
    setLoaderkit(true);
    if ((!Username || Username === '' || Username === 'undefined') && (!Password || Password === '' || Password === 'undefined')) {
      cprRef.current.focus();
      setLoaderkit(false);
      return false;
    }
    if (!Username && !Password) {
      cprRef.current.focus();
      if(cprRef.current){
        cprRef.current.style.borderColor = "red";
      }
      setLoaderkit(false);
      return false;
    } else {
 
      var infoData = {
        "username": Username,
        "password": Password
      }
      var payload = await AuthApi(infoData,`${process.env.REACT_APP_API_BASE_URL}api/selfcare/v2/auth`);
      if (payload.status === 200) {
        setCPRRight(true);
        let b = {
          token: payload.data.token,
          infoType: 7,
        }
        //contextDavaData.fetchDavadata(b);
        setTimeout(() => {
          handleNextPrevClick(2, data);
        }, 1000);
      } else {
        if(cprRef.current){
          cprRef.current.style.borderColor = "red";
        }
        setCPRWrong(true);
         // handleNextPrevClick(2, data);

        }
      setLoaderkit(false);
    }

  }



  return (
    <StepContainer md="12">
      <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            borderRadius: '10px',
          }}
        >
          <FlexColumnContainer className="px-csm-0 p-0">
            <div
              style={{
                textAlign: 'center',
                padding: '0px 0 20px 0',
                fontWeight: 'bold',
              }}
            >
            <span className="roboto-bold">
            Indtast dit kundenummer og dit kodeord. <br/>Hvis du ikke kan huske dit kodeord - Gå til{' '}<a href="https://fairpris.min-forsyning.dk" target="_blank" rel="noopener noreferrer"> https://fairpris.min-forsyning.dk</a>{' '}og tryk glemt kodeord, så du får tilsendt et nyt.
          </span>
            </div>
            {/* <Row xs={1} sm={1} md={1} lg={1} xl={1} className="g-4 col-lg-12 col-12 mb-3">
              <Col className="px-csm-0 position-relative">
                <Form.Control
                  ref={cprRef}
                  type="text"
                  name="cpr"
                  placeholder="CPR Nummer"
                  className="mb-3 mb-md-0 mb-lg-0 shadow-none"
                  onChange={(e) => {
                    const regex = /^(?:(?:31(?:0[13578]|1[02])|(?:30|29)(?:0[13-9]|1[0-2])|(?:0[1-9]|1[0-9]|2[0-8])(?:0[1-9]|1[0-2]))[0-9]{2}\s?-?\s?[0-9]|290200\s?-?\s?[4-9]|2902(?:(?!00)[02468][048]|[13579][26])\s?-?\s?[0-3])[0-9]{3}|000000\s?-?\s?0000$/;
                    if (!regex.test(e.target.value)) {
                      e.preventDefault();
                      e.target.value = e.target.value.replace(/\D/g, "");
                      setCPRNumber(e.target.value);
                    } else {
                      setCPRNumber(e.target.value)
                    }
                  }}
                  defaultValue={CPRNumber}
                />
                {Loaderkit ?
                  <img src="../images/loader.gif" style={{ width: '31px', right: '20px', top: '50%', transform: 'translateY(-50%)' }} className="position-absolute" />
                  : null}
                {CPRRight ?
                  <img src="../images/right_check.svg" style={{ width: '31px', right: '20px', top: '50%', transform: 'translateY(-50%)' }} className="position-absolute" />
                  : null}
                {CPRWrong ?
                  <img src="../images/error_sign.svg" style={{ width: '31px', right: '20px', top: '50%', transform: 'translateY(-50%)' }} className="position-absolute" />
                  : null}
              </Col>
            </Row> */}
            <Row md={1} className="mt-4 col-lg-12 col-12 justify-column text-start">
              <Col className="d-inline-flex gap-1 col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0 mb-3">
                <Form.Control
                ref={cprRef}
                type="text"
                  name="username"
                  placeholder="Kundenummer"
                  onChange={(e) => setUsername(e.target.value)}
                  value={Username}
                />
              </Col>
              <Col className="d-inline-flex gap-1 col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0 mb-3">
                <Form.Control
                ref={passRef}
                type="password"
                  name="password"
                  placeholder="Kodeord"
                  className=""
                  onChange={(e) => setPassword(e.target.value)}
                  value={Password}
                />
              </Col>
            </Row>
            <FlexColumnContainer className="col-lg-12 col-12 px-csm-0">
              <div className="col-md-12 col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5 border-2 btn-outline-dark"
                  outline
                  onClick={() => handleNextPrevClick(1, data)}
                >
                  Tilbage
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5"
                  onClick={() => handleNextClick()}
                >
                  Fortsæt
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default CPRCheckStep;