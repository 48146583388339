import axios from "axios";

const AuthApi = async (params = [], url = '') => {

	var parameters = {
		method: 'post',
		url: url,
		headers: {
			"x-cubs-dk-ns": `cubs-live`,
			"Content-Type": "application/json",
		},
		data:params
	};
	
	try {
		var response = await axios(parameters);
		return response;
	} catch (error) {
		return error;
	}
	
};

export default AuthApi;