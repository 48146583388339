import { createContext, useContext, useState } from "react";
const PrisContext = createContext();

export default function AppStore({ children }) {
  const [Loader, setLoader] = useState(false);
  const [davaData, setdavaData] = useState([]);
  const [AuthToken, setAuthToken] = useState();
  const [Area, setArea] = useState();
  const [PowerDelivered, setPowerDelivered] = useState();
  const [AddressValue, setAddressValue] = useState();
  const [date, setdate] = useState();
  const [GreenElectricity, setGreenElectricity] = useState();
  const [ProfitStream, setProfitStream] = useState();
  const [GSRNNumber, setGSRNNumber] = useState('');
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();
  const [CPRNumber, setCPRNumber] = useState();
  const [Email, setEmail] = useState();
  const [phone, setphone] = useState();
  const [Accept, setAccept] = useState(0);
  const [PaymentMethod, setPaymentMethod] = useState('');
  const [registerNumber, setregisterNumber] = useState('');
  const [Account, setAccount] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [externalId, setexternalId] = useState(null);
  const [ProductBundleCode, setProductBundleCode] = useState('');
  const [ProductBundleName, setProductBundleName] = useState('');
  const [exAgreement, setexAgreement] = useState('');

  // Select dava data
  const fetchDataHandler = (data) => {
    if (data.infoType === 2) {
      if (data.cpr) {
        setCPRNumber(data.cpr);
      }else if(data.product){
        setArea(data.product);
      }
    } else if (data.infoType === 3) {
      setAddressValue(data?.address);
      setPowerDelivered(data?.power_delivered);
      setdate(data?.date);
    } else if (data.infoType === 4) {
		console.log(data);
      setGreenElectricity(data?.greenElectricity);
      if(data.address && data.address !=''){
        setAddressValue(data?.address);
      }
      if(data.power_delivered && data.power_delivered !=''){
        setPowerDelivered(data?.power_delivered);
      }
      if(data.date && data.date !=''){
        setdate(data?.date);
      }
 
    } else if (data.infoType === 5) {
      setProfitStream(data.profit_stream);
      setGSRNNumber(data.gsrn_number);
      setexAgreement(data.agreement);
    } else if (data.infoType === 6) {
      setfirstName(data.first_name);
      setlastName(data.last_name);
      setCPRNumber(data.cpr);
      setphone(data.phone);
      setEmail(data.email);
      setAccept(data.Accept);
      setexternalId(data.externalId);
    } else if (data.infoType === 7) {
      //setCPRNumber(data.cpr);
      setAuthToken(data.token);
    } else if (data.infoType === 'payment') {
      setPaymentMethod(data.method);
      setregisterNumber(data.registerNo);
      setAccount(data.account);
    } else if (data.infoType === 'msg') {
      setErrorMsg(data.msg);
    } else if (data.infoType === 'loader') {
      setLoader(data.loader);
    } else if (data.infoType === 'token') {
      setAuthToken(data.token);
    } else if (data.infoType === 'product_bundle') {
      setProductBundleCode(data?.bundlecode);
      setProductBundleName(data?.bundlename);
    } else {
      setdavaData(data);
    }
  };
  const contextDavaData = {
    Loader: Loader,
    AuthToken: AuthToken,
    Area: Area,
    PowerDelivered: PowerDelivered,
    date: date,
    AddressValue:AddressValue,
    GreenElectricity: GreenElectricity,
    ProfitStream: ProfitStream,
    GSRNNumber: GSRNNumber,
    exAgreement: exAgreement,
    firstName: firstName,
    lastName: lastName,
    CPRNumber: CPRNumber,
    Email: Email,
    phone: phone,
    Accept: Accept,
    PaymentMethod: PaymentMethod,
    registerNumber: registerNumber,
    Account: Account,
    ErrorMsg: ErrorMsg,
    externalId: externalId,
    ProductBundleCode: ProductBundleCode,
    ProductBundleName: ProductBundleName,
    davadata: davaData,
    fetchDavadata: fetchDataHandler,
  };

  return (
    <PrisContext.Provider value={{ contextDavaData }}>
      {children}
    </PrisContext.Provider>
  );
}

export function usePrisContext() {
  return useContext(PrisContext);
}