import React, { useRef, useState, useContext } from "react";
import styled from '@emotion/styled'
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { usePrisContext } from "../../context/AppContext";
import Modal from 'react-bootstrap/Modal';
function SolarCellElectricityStep({ handleNextPrevClick }) {
  const { contextDavaData } = usePrisContext();
  const GSRNRef = useRef(null);
  const CHECKRef = useRef(null);
  const [ProfitStream, setProfitStream] = useState( (contextDavaData.Area === 'FAIR Produktion')?'profit_stream':contextDavaData.ProfitStream);
  const [GSRNNumber, setGSRNNumber] = useState(contextDavaData.GSRNNumber);
  const [IsRequired, setIsRequired] = useState(true);
  const [show, setShow] = useState(false);
  const [exAgreement , setexAgreement] = useState( (contextDavaData.Area === 'FAIR Produktion')?'Ja':(contextDavaData.Exeagreement??'Nej'));
 

  var data = {
    profit_stream: ProfitStream,
    gsrn_number: GSRNNumber,
    IsRequired: IsRequired,
    agreement: exAgreement,
  }
  const handleClose = () => setShow(false);
 
	const handleCheck = () => {
		setIsRequired(IsRequired ? false : true);
		if (contextDavaData.Area != 'FAIR Produktion') {
			setGSRNNumber('');
			GSRNRef.current.value='';
		}
	}

  const handleBackClick = () => {
    if (contextDavaData.Area != 'FAIR Produktion') {
	  //need to reverse in case if need this step once again
      //handleNextPrevClick(3,[])//old logic
      handleNextPrevClick(2,[])
    }else{
      handleNextPrevClick(2,[])
    }
  }
	const handleClick = () => {
		if(ProfitStream){
			if (contextDavaData.Area != 'FAIR Produktion') {
				if (IsRequired && (GSRNNumber == '' || GSRNNumber.length < 16 || GSRNNumber.length > 18)) {
				  GSRNRef.current.focus();
				} else {
					//setGSRNNumber('')		
					setShow(true);
				}
			}else{
				if ( (GSRNNumber == '' || GSRNNumber.length < 16 || GSRNNumber.length > 18)) {
				  GSRNRef.current.focus();
				}else if ( IsRequired ) {
				  CHECKRef.current.focus();
				} else {
				  setexAgreement('');
				  handleNextPrevClick(5, data);
				}
			}				
		} else {
			//setGSRNNumber('')						  
		   setexAgreement('');
		  handleNextPrevClick(5, data);
		}
	} 
	
	const handleNextClick = () => {
		handleNextPrevClick(5, data);
	}
	const Agreement= (e) =>{
		setexAgreement(e);
	}	
	const onStreamChange= (e) =>{
    if(contextDavaData.Area != 'FAIR Produktion'){
      setProfitStream(ProfitStream ? '' : e.target.value);
    }else{
      setProfitStream('profit_stream');
    }

  }	
  return (
    
	  
    <StepContainer md="12">
	<>
	<Modal show={show}>
	 <Modal.Dialog>
     

        <Modal.Body>
		  <div  className= "p-4" style={{ textAlign: 'center',fontWeight: 'bold', }} >
              <span className="roboto-bold">Har du salg af overskydende strøm hos et eksisterende selskab som skal flyttes?</span>
            </div>
         <Row xs={1} md={1} lg={1} className="g-4 m-0 col-lg-12 col-12">
              <Col className="px-csm-0">
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="mr-35 inter-font f-15 h6">Ja, Jeg har en eksisterende aftale</Card.Title>
                    <input type="radio" name="agreement" aria-label="Area" className="area_radio position-absolute opacity-0" value={'Ja'} onChange={(e) => Agreement(e.target.value)} defaultChecked={exAgreement === 'Ja' ? 'checked' : ''} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
                      <li className="inter-font f-75">Vælges hvis du allerede har en aftale med et andet selskab om salg af din strøm</li>
                      <li className="inter-font f-75">Vi overtager produktionen samtidigt med dit leverandør skifte træder i kraft, og du bliver kunde hos Fairpris EL</li>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="px-csm-0">
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="text-left mr-35 inter-font f-15 h6">Nej, jeg har ingen aftale om salg af strøm</Card.Title>
                    <input type="radio" name="agreement" className="area_radio position-absolute opacity-0" onChange={(e) => Agreement(e.target.value)} value={'Nej'} defaultChecked={exAgreement === 'Nej' ? 'checked' : ''} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
                      <li className="inter-font f-75">Vælg denne mulighed hvis det er første gang du tilmelder dit anlæg og du ikke har en eksisterende aftale i forvejen</li>
                      <li className="inter-font f-75">Vi tilmelder dit anlæg hurtigst muligt, så din overskudsproduktion ikke går til spilde</li>
            
                    </Col>
                  </Card.Body>
                </Card>
              </Col>  
  
            </Row>
			  <FlexColumnContainer className="col-lg-12 col-12 px-csm-0">
              <div className="col-md-12 col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                <CDBBtn color="dark" flat circle={false}  className="float-end px-5 py-2 rounded-5 border-2 btn-outline-dark" outline  onClick={handleClose}>
                  Tilbage
                </CDBBtn>
                <CDBBtn color="dark" flat  circle={false} className="float-end px-5 py-2 rounded-5" onClick={() => handleNextClick()}>
                  Fortsæt!
                </CDBBtn>
              </div>
            </FlexColumnContainer>
        </Modal.Body>


      </Modal.Dialog>
	  </Modal>
		
	</>
      <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            borderRadius: '10px',
          }}
        >
		  
		
          <FlexColumnContainer className={`px-csm-0 p-0`}>
            <div
              style={{
                textAlign: 'center',
                padding: '0px 0 20px 0',
                fontWeight: 'bold',
              }}
            >
              <span className="roboto-bold">Har du solceller og ønsker at sælge os din overskydende strøm produktion?</span>
            </div>

            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="g-4 col-lg-12 col-12">
              <Col className={`px-csm-0`}>
			{contextDavaData.Area != 'FAIR Produktion'?(
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="mr-35 inter-font f-26"><strong>FAIR </strong>Produktion</Card.Title>
                    <input type="checkbox"  name="profit_stream" className=" area_radio position-absolute opacity-0" value={'profit_stream'} 
                    onChange={onStreamChange} defaultChecked={ProfitStream ? true : false} 
                    disabled={contextDavaData.Area === 'FAIR Produktion'}   
                    />
                    <div className="area_radio_btn">
                      <img src="../images/star.png" alt="" />
                    </div>
                    <Row className="align-items-end">
                      <Col lg={10}>
                        <li className="mb-0 inter-font f-75">Producerer dit solcelleanlæg mere strøm end du kan bruge? Så køber vi hos Fairpris gerne din overskudstrøm. </li>
                        <li className="mb-0 inter-font f-75">Som elkunde hos os kan du kvit og frit sælge din overskudsstrøm til spotpris </li>
                      </Col>
                      <Col lg={2} className="text-end">
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
				):''}
              </Col>
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="g-4 col-lg-12 col-12 mb-3 mt-2">
              <Col className="mt-3 px-csm-0">
                <Form.Control
                  ref={GSRNRef}
                  type="text"
				  id="GSRNRef"
                  name="gsrn_number"
				  disabled={(contextDavaData.Area === 'FAIR Produktion')?false:((IsRequired)?false:true)}
                  placeholder="GSRN nummer for dit anlæg"
                  onChange={(e) => {
 						if(!IsRequired && contextDavaData.Area != 'FAIR Produktion'){
							setGSRNNumber(e.target.value)
							//setGSRNNumber('')						  
						}else{
							const regex = /^\d{16,18}$/;
							if (!regex.test(e.target.value)) {
								e.preventDefault();
								e.target.value = e.target.value.replace(/\D/g, "").slice(0, 18);
								setGSRNNumber(e.target.value)
							} else {
								setGSRNNumber(e.target.value)
							}
						}
				  }
				  }
                  defaultValue={GSRNNumber}

                />
              </Col>
              <Col className="mt-3 px-csm-0">

			{contextDavaData.Area === 'FAIR Produktion'?(
				  <Col className="px-csm-0">
 						<p className="text-start inter-font f-95">Du finder dit GSRN nummer i den mail du har modtaget fra dit netselskab i forbindelse med tilmeldingen af dit solcelleanlæg. </p>
						<p className="text-start inter-font f-95">Hvis du ikke kan finde dit GSRN nummer skal du ringe ind til vores kundeservice istedet. <br/>Så vil vores medarbejdere hjælpe dig med at finde det for dig. </p>
                  </Col>

				):''}
                <Form.Check
                  inline
                  label={contextDavaData.Area === 'FAIR Produktion'?`Ved at klikke "Tilmeld anlægget" bekræfter jeg, at jeg accepterer at oprette en aftale hos FairPris ApS. Jeg er indforstået med, at FairPris ApS vil informere energinet om kundeforholdet på mine vegne, og jeg accepterer samtidig FairPris ApS' handelsbetingelser.`:`Jeg kan ikke finde eller huske mit GSRN nummer nu og vil gerne ringes op af kundeservice.`}
                  ref={CHECKRef}
                  name="customer_service"
                  type={'checkbox'}
                  id={`inline-checkbox-1`}
                  className="agreement_check col-12 inter-font"
                  onChange={(e) => 
					handleCheck()
				  }
                  defaultChecked={IsRequired ? false : true}
                />
              </Col>
            </Row>
            <FlexColumnContainer className="col-lg-12 col-12 px-csm-0">
              <div className="col-md-12 col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5 border-2 btn-outline-dark"
                  outline
                  onClick={() => handleBackClick()}
                >   Tilbage
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5"
                  onClick={() => handleClick()}
                >
                  {contextDavaData.Area === 'FAIR Produktion'?'Tilmeld anlægget!':(ProfitStream ? 'Fortsæt!' : 'Fortsæt uden')}
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default SolarCellElectricityStep;