import React, { useState } from "react";
import styled from '@emotion/styled'
import Card from "react-bootstrap/Card";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row';
import { usePrisContext } from "../../context/AppContext";

function GreenElectricityStep({ handleNextPrevClick }) {
  const { contextDavaData } = usePrisContext();
  const [GreenElectricity, setGreenElectricity] = useState(contextDavaData?.GreenElectricity);
  const [Area, setArea] = useState(contextDavaData?.Area ? contextDavaData?.Area : 'FAIR Flex');
   var greenInfo = {
    greenElectricity: GreenElectricity
  }
   
  return (
    <StepContainer md="12">
      <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            borderRadius: '10px',
          }}
        >
          <FlexColumnContainer className={`px-csm-0 p-0`}>
            <div
              style={{
                textAlign: 'center',
                padding: '0px 0 20px 0',
                fontWeight: 'bold',
              }}
            >
              <span className="roboto-bold">Vil du være med til at gøre en forskel og tilkøbe grøn strøm?</span>
            </div>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="g-4 col-lg-12 col-12">
              <Col className={`px-csm-0`}>
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                    <Card.Title className="mr-35 inter-font f-26"><strong>Grøn </strong>Strøm</Card.Title>
                    <input type="checkbox" name="green_electricity" className="area_radio position-absolute opacity-0" value={'GRØN'} onChange={(e) => setGreenElectricity(GreenElectricity ? '' : e.target.value)} defaultChecked={GreenElectricity ? 'checked' : ''} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Row className="align-items-end">
                      <Col lg={10}>
                        <li className="mb-4 inter-font list-style-none p-0 f-75">Med grøn strøm støtter du den grønne omstilling ved at vi køber strømmen fra dansk vindenergi (baseret på oprindelsescerifikater styret af staten) </li>
                        <li className="mb-0 inter-font list-style-none p-0 f-75"><b>FAIR Flex</b> 10 øre i grønt tillæg pr. kWh inkl. moms / <b>FAIR Fast</b> 20 kr i abonnement </li>
                      </Col>
                      <Col lg={2} className="text-end">
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <FlexColumnContainer className="col-lg-12 col-12 px-csm-0">
              <div className="col-md-12 col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5 btn-outline-dark"
                  outline
                  onClick={() => handleNextPrevClick(2,[])}
                  
                >
                  Tilbage
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5"
                  onClick={() => handleNextPrevClick(4, greenInfo)}
                >
				{GreenElectricity ? 'Fortsæt!' : 'Fortsæt uden'} 
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default GreenElectricityStep;
