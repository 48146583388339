import React, { useRef, useState } from "react";
import styled from '@emotion/styled'
import Form from "react-bootstrap/Form";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { usePrisContext } from "../../context/AppContext";
import MainApi from "../../api/mainApi";
import { format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SaleConfirmApi from "../../api/saleConfirmApi";

function FamilyInfoStep({ handleNextPrevClick }) {
  const nameRef = useRef(null);
  const cprRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
 
  const { contextDavaData } = usePrisContext();
  const [firstName, setfirstName] = useState(contextDavaData.firstName);
  const [lastName, setlastName] = useState(contextDavaData.lastName);
  const [CPRNumber, setCPRNumber] = useState(contextDavaData.CPRNumber);
  const [Email, setEmail] = useState(contextDavaData.Email);
  const [phone, setphone] = useState(contextDavaData.phone);
  const [phoneCode, setphoneCode] = useState('+45');
  const [Accept, setAccept] = useState(0);
  const [Loaderkit, setLoaderkit] = useState(false);
  const [Msg, setMsg] = useState('');
  const [date1, setDate] = useState(new Date());
  var start_date = '';
  
  if (contextDavaData.Area === 'FAIR Produktion') {
		start_date = contextDavaData.date;
  }else{
  	if(contextDavaData.PowerDelivered==='SKIFT til'){
		if(contextDavaData.exAgreement ==='Nej'){
			start_date = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate()}`; 
		}else{
			start_date = contextDavaData.date;
		}
	}else{
		start_date = contextDavaData.date;
	}
  }

 /* 
  if (contextDavaData.Area === 'FAIR Produktion') {
	  if (contextDavaData.exAgreement === 'Nej') {
 		  //current date
      start_date = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate()}`;
    } else {
		 //contextDavaData.PowerDelivered === 'SKIFT til' ? 'change_of_supplier' : 'move_in'
		if(date1.getDate() <= 14){
 			// just next month 1st date
			start_date = `${date1.getFullYear()}-${(date1.getMonth() + 2).toString().padStart(2, '0')}-01`;
		} else{
 			// 1st date after 2 months
			start_date = `${date1.getFullYear()}-${(date1.getMonth() + 3).toString().padStart(2, '0')}-01`;
		}
    }  
  }else{	  
	  if (contextDavaData.ProfitStream === 'profit_stream') {
		if (contextDavaData.exAgreement === 'Nej') {
 
		  start_date = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate()}`;
		} else {
 		  start_date = contextDavaData.date;
		}
	   } else {
 		start_date = contextDavaData.date;
	} 
  } 
  
*/
   const acceptFun = async (e) => {
    setLoaderkit(true);
    var productBundleCode = '';
    var productBundleName = '';

    if (contextDavaData.davadata[0].data.postnr >= 5000) {
      if (contextDavaData.Area === 'FAIR Flex') {
        if (contextDavaData.GreenElectricity) {
          productBundleName = `${contextDavaData.Area} ${contextDavaData.GreenElectricity} DK1`;
          productBundleCode = 'PP_7';
        } else {
          productBundleName = `${contextDavaData.Area} DK1`;
          productBundleCode = 'PP_3';
        }
      } else if (contextDavaData.Area === 'FAIR Produktion') {
        productBundleName = `${contextDavaData.Area} DK1`;
        productBundleCode = 'PP_11';
      } else {
        if (contextDavaData.GreenElectricity) {
          productBundleName = `${contextDavaData.Area} ${contextDavaData.GreenElectricity} DK1`;
          productBundleCode = 'PP_5';
        } else {
          productBundleName = `${contextDavaData.Area} DK1`;
          productBundleCode = 'PP_1';
        }
      }
    } else {
      if (contextDavaData.Area === 'FAIR Flex') {
        if (contextDavaData.GreenElectricity) {
          productBundleName = `${contextDavaData.Area} ${contextDavaData.GreenElectricity} DK2`;
          productBundleCode = 'PP_8';
        } else {
          productBundleName = `${contextDavaData.Area} DK2`;
          productBundleCode = 'PP_4';
        }
      } else if (contextDavaData.Area === 'FAIR Produktion') {
        productBundleName = `${contextDavaData.Area} DK2`;
        productBundleCode = 'PP_12';
      } else {
        if (contextDavaData.GreenElectricity) {
          productBundleName = `${contextDavaData.Area} ${contextDavaData.GreenElectricity} DK2`;
          productBundleCode = 'PP_6';
        } else {
          productBundleName = `${contextDavaData.Area} DK2`;
          productBundleCode = 'PP_2';
        }
      }
    }

    contextDavaData.fetchDavadata({
      bundlecode: productBundleCode,
      bundlename: productBundleName,
      infoType: 'product_bundle'
    });
    var infoData = {
      "source": "signup",
      "agreement_creation_type": "new",
      "sales_person_name": "Website signup",
      "send_invoice_on_email": true,
      //"product_bundle_code": productBundleCode,
      "product_bundle_name": productBundleName,
      "cpr": CPRNumber,
      "address": {
        "dar_reference": contextDavaData.davadata[0].data.id,
        "first_name": firstName,
        "last_name": lastName,
        "street_name": contextDavaData.davadata[0].data.vejnavn,
        "street_no": contextDavaData.davadata[0].data.husnr,
        "side": contextDavaData.davadata[0].data.dør,
        "floor": contextDavaData.davadata[0].data.etage,
        "zip_code": contextDavaData.davadata[0].data.postnr,
        "city": contextDavaData.davadata[0].data.postnrnavn,
        "phone": phone,
        "email": Email
      },
      "delivery": {
        "identifier": contextDavaData.GSRNNumber || '',
        "type": 'electrical',//contextDavaData.Area === 'FAIR Produktion' ? "production" : "electrical",
        "start_date": start_date,
        "creation_type": contextDavaData.PowerDelivered === 'SKIFT til' ? 'change_of_supplier' : 'move_in',
        "metering_point_type": contextDavaData.Area === 'FAIR Produktion' ? "E18" : "E17"
      },
      "product_timeline": [
        {
          "product_bundle_code": productBundleCode
        }
      ]
    }
    //"identifier": contextDavaData.ProfitStream==='profit_stream' ? '':contextDavaData.GSRNNumber,

    if (contextDavaData.Area == 'FAIR Produktion') {
      infoData.delivery.tags = ["Produktion"];
	  //payment_subscription
	 
	  infoData.payment_subscription =  {"method": "choose-later"};
    }
    //return false;
    var payload = await MainApi(infoData, `${process.env.REACT_APP_API_BASE_URL}api/sale/v4`, `${contextDavaData?.AuthToken}`);
    if (payload.status == 200) {
      setLoaderkit(false);
      var infoDatas = {
        first_name: firstName,
        last_name: lastName,
        cpr: CPRNumber,
        email: Email,
        phone: phoneCode + phone,
        accept: Accept,
        externalId: payload?.data?.external_id,
        infoType: 6
      }
      
      contextDavaData.fetchDavadata(infoDatas);
      if(contextDavaData.Area === 'FAIR Produktion'){
       saleConfirmApi(payload?.data?.external_id);
      }else{
        handleNextPrevClick(6)
      }
    } else {
      setMsg('Something went wrong, please try again.');
    }
    setTimeout(() => {
      setMsg('');
      setLoaderkit(false);
    }, 1000);
  }
// Sale confirm ation API call function
  async function saleConfirmApi(externalId) {
    const payloadConfirm = await SaleConfirmApi([], `${process.env.REACT_APP_API_BASE_URL}api/sale/v3/${externalId}/confirm`, `${contextDavaData?.AuthToken}`);
    if (payloadConfirm?.status === 200) {
      if (contextDavaData.Area === 'FAIR Produktion') {
        let estimateInfo = {
          "manual_estimated_usage": 0
        }
        var updateManualEstimated = await MainApi(estimateInfo, `${process.env.REACT_APP_API_BASE_URL}api/electrical/v2/${externalId}/update-manual-estimated-usage`, `${contextDavaData?.AuthToken}`);
       }
      if (contextDavaData.ProfitStream && contextDavaData.ProfitStream !== undefined) {
        handleProductionApi('');
      } else {
        window.location.href = 'https://fairpris.dk/el/tak-for-at-blive-el-kunde/';
      }
      setMsg(payloadConfirm?.data?.message);
      setLoaderkit(false);
      //handleNextPrevClick('payment', paymentdata)
    } else {
      setMsg('Something went wrong, please try again');
      setLoaderkit(false);
    }
  }

  // production check box function
  async function handleProductionApi(redirect_url) {
    var productBundleCode = '';
    var productBundleName = '';
    if (contextDavaData.davadata[0].data.postnr >= 5000) {
      if (contextDavaData.Area === 'FAIR Flex') {
        productBundleName = `Fairpris FLEX PRODUKTION DK1`;
        productBundleCode = 'PP_11';
      } else if (contextDavaData.Area === 'FAIR Produktion') {
        productBundleName = `${contextDavaData.Area} DK1`;
        productBundleCode = 'PP_11';
      } else {
        productBundleName = `Fairpris FAST PRODUKTION DK1`;
        productBundleCode = 'PP_9';
      }
    } else {
      if (contextDavaData.Area === 'FAIR Flex') {
        productBundleName = `Fairpris FLEX PRODUKTION DK2`;
        productBundleCode = 'PP_12';
      } else if (contextDavaData.Area === 'FAIR Produktion') {
        productBundleName = `${contextDavaData.Area} DK2`;
        productBundleCode = 'PP_12';
      } else {
        productBundleName = `Fairpris FAST PRODUKTION DK2`;
        productBundleCode = 'PP_10';
      }
    }


    const productionSaleOptions = {
      "accept": true,
      "source": "signup",
      //"product_bundle_code": productBundleCode,
      "cpr": contextDavaData.CPRNumber,
      "agreement_creation_type": (contextDavaData.Area==='FAIR Produktion')?"new":"use_last",
      "send_invoice_on_email": true,
      "address": {
        "dar_reference": contextDavaData.davadata[0].data.id,
        "first_name": contextDavaData?.firstName,
        "last_name": contextDavaData?.lastName,
        "phone": contextDavaData?.phone,
        "email": contextDavaData?.Email,
        "street_name": contextDavaData.davadata[0].data.vejnavn,
        "street_no": contextDavaData.davadata[0].data.husnr,
        "floor": contextDavaData.davadata[0].data.etage,
        "side": contextDavaData.davadata[0].data.dør,
        "zip_code": contextDavaData.davadata[0].data.postnr,
        "city": contextDavaData.davadata[0].data.postnrnavn
      },
      "delivery": {
        "identifier": contextDavaData.GSRNNumber,
        "type": 'electrical',//contextDavaData.Area === 'FAIR Produktion' ? "production" : "electrical",
        "creation_type": contextDavaData.PowerDelivered === 'SKIFT til' && contextDavaData.exAgreement === 'Ja' ? 'change_of_supplier' : 'move_in',
        "start_date": start_date,
        "metering_point_type": contextDavaData.Area === 'FAIR Produktion' ? "E18" : "E17"
      },
      "product_timeline": [
        {
          "product_bundle_code": productBundleCode
        }
      ]
    }
    if (contextDavaData.Area == 'FAIR Produktion') {
      productionSaleOptions.delivery.tags = ["Produktion"];
	  productionSaleOptions.payment_subscription =  {"method": "choose-later"};
}
	
    if (redirect_url != '') {
      localStorage.setItem('items', JSON.stringify({ 'ProfitStream': contextDavaData.ProfitStream, 'externalId': contextDavaData.externalId, 'AuthToken': contextDavaData?.AuthToken, 'saleData': productionSaleOptions }));

      window.location.href = redirect_url;
      //window.location.href='/payment-success';
    } else {
      // sale v3 api
      var payloadSale = await MainApi(productionSaleOptions, `${process.env.REACT_APP_API_BASE_URL}api/sale/v4`, `${contextDavaData?.AuthToken}`);
      if (payloadSale.status == 200) {
        window.location.href = 'https://fairpris.dk/el/tak-for-at-blive-el-kunde/';
      } else {
        setMsg('Something went wrong, please try again');
      }
    }
  }
  const confirmCheck = () => {
    confirmAlert({
      title: 'Bekræft at de indtastede oplysninger er korrekte',
      message: 'Bekræft venligst, at informationerne er korrekte. Ved at trykke \'Ja\' bekræfter du, at oplysningerne er korrekte.',
      buttons: [
        {
          label: 'Nej',
          className: 'rounded-5 border-2 btn-outline-dark ehDhhb ',
          onClick: () => ''
        },
        {
          label: 'Ja det er korrekt',
          className: 'rounded-5',
          onClick: () => acceptFun()
        }
      ]
    });
  }

  const nextHandle = async (e) => {
    if (!firstName) {
      nameRef.current.focus();
    } else if (!CPRNumber || CPRNumber === '' || CPRNumber === 'undefined' || CPRNumber.length > 10 || CPRNumber.length < 10) {
      cprRef.current.focus();
    } else if (!Email) {
      emailRef.current.focus();
    } else if (!phone) {
      phoneRef.current.focus();
    } else {
      confirmCheck();
      return false;

    }
  }

  return (
    <StepContainer>
      <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            borderRadius: '10px',
          }}
        >

          <FlexColumnContainer className="p-0 px-csm-0">
            <div
              style={{
                textAlign: 'center',
                padding: '0px 0 20px 0',
                fontWeight: 'bold',
              }}
            >
              <span className="roboto-bold">Indtast dine stamoplysninger - sørg for alle oplysninger er opdaterede!</span>
            </div>
            <Row md={1} className="mt-4 col-lg-12 col-12 justify-column text-start">
              <Col className="d-inline-block col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0">
                <Form.Control
                  ref={nameRef}
                  type="text"
                  name="first_name"
                  placeholder="Fornavn"
                  className="mb-3 mb-md-0 mb-lg-0"
                  onChange={(e) => setfirstName(e.target.value)}
                  defaultValue={firstName}
                />
              </Col>
              <Col className="d-inline-block col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0">
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="Efternavn"
                  onChange={(e) => setlastName(e.target.value)}
                  defaultValue={lastName}
                />
              </Col>
            </Row>
            <Row md={1} className="mt-4 col-lg-12 col-12 justify-column text-start">
              <Col className="d-inline-block col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0">
                <Form.Control
                  ref={cprRef}
                  type="text"
                  name="cpr"
                  placeholder="CPR Nummer"
                  className="mb-3 mb-md-0 mb-lg-0"
                  onChange={(e) => {
                    const regex = /^(?:(?:31(?:0[13578]|1[02])|(?:30|29)(?:0[13-9]|1[0-2])|(?:0[1-9]|1[0-9]|2[0-8])(?:0[1-9]|1[0-2]))[0-9]{2}\s?-?\s?[0-9]|290200\s?-?\s?[4-9]|2902(?:(?!00)[02468][048]|[13579][26])\s?-?\s?[0-3])[0-9]{3}|000000\s?-?\s?0000$/;
                    if (!regex.test(e.target.value)) {
                      e.preventDefault();
                      e.target.value = e.target.value.replace(/\D/g, "");
                      setCPRNumber(e.target.value);
                    } else {
                      setCPRNumber(e.target.value)
                    }
                  }}
                  defaultValue={CPRNumber}
                />
              </Col>
              <Col className="d-inline-block col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0">
                <Form.Control
                  ref={emailRef}
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  defaultValue={Email}
                />
              </Col>
            </Row>
            <Row md={1} className="mt-4 col-lg-12 col-12 justify-column text-start">
              <Col className="d-inline-flex gap-1 col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0 mb-3">
                <Form.Control
                  style={{ width: '80px' }}
                  onChange={(e) => {
                    const regex = /^(00|45|0045)$/;
                    if (!regex.test(e.target.value)) {
                      e.preventDefault();
                      e.target.value = e.target.value.replace(/\D/g, "").slice(0, 2);
                    } else {
                      setphoneCode(e.target.value);
                    }
                  }}
                  value={phoneCode}
                />
                <Form.Control
                  ref={phoneRef}
                  type="text"
                  name="phone"
                  placeholder="Telefon"
                  className=""
                  onChange={(e) => {
                    const regex = /^\d{0,8}$/;
                    if (!regex.test(e.target.value)) {
                      e.preventDefault();
                      e.target.value = e.target.value.replace(/\D/g, "").slice(0, 8);;
                    } else {
                      setphone(e.target.value)
                    }
                  }}
                  defaultValue={phone}
                />
              </Col>
              <Col className="d-flex col-12 col-lg-6 col-xl-6 col-md-6 px-csm-0 ">
                <Form.Check
                  inline
                  name="news"
                  type={'checkbox'}
                  id={`inline-checkbox-1`}
                  className="agreement_check inter-font"
                  value={1}
                  onChange={(e) => setAccept(e.target.checked)}
                />
                <label htmlFor="inline-checkbox-1" className="inline inter-font f-75" style={{ userSelect: 'none' }}>Ja tak, Jeg vil gerne modtage nyheder og tilbud fra FairPris ApS på email, SMS og Telefon. Se mere i vores <a href="https://fairpris.dk/el/privatlivspolitik/" target="_blank" style={{ color: 'var(--bs-body-color)' }}>Privatlivspolitik</a></label>
              </Col>
            </Row>
            {Msg ?
              <Col className="mt-5 text-center px-csm-0">
                <p className="alert alert-danger">{Msg ? Msg : null}</p>
              </Col>
              : null}
            <FlexColumnContainer className="col-lg-12 col-12">
              <div className="col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5 border-2 btn-outline-dark"
                  outline
                  onClick={() => handleNextPrevClick(4, [])}
                >
                  Tilbage
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5"
                  onClick={() => nextHandle()}
                >
                  {Loaderkit ?
                    <img src="../images/loader.gif" style={{ width: '20px', marginRight: '10px' }} />
                    : null}
                  Fortsæt
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>

        </div>
      </div>
    </StepContainer>
  );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default FamilyInfoStep;
