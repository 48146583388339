import React, { useEffect,useRef, useState, useContext } from "react";
 import styled from '@emotion/styled'
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { usePrisContext } from "../../context/AppContext";
import DavaKeyApi from "../../api/getDavakeyApi";
import DatePicker from "react-datepicker";
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns'; 
import da from 'date-fns/locale/da';
registerLocale('da', da);

function PowerDeliveredStep({ handleNextPrevClick }) {
  const { contextDavaData } = usePrisContext();
  const addressRef = useRef(null);
  
  const [date2, setDate2] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [PowerDelivered, setPowerDelivered] = useState(contextDavaData?.PowerDelivered ? contextDavaData?.PowerDelivered : 'SKIFT til');
  const [Area, setArea] = useState(contextDavaData?.Area ? contextDavaData?.Area : 'FAIR Flex');
  const [AddressValue, setAddressValue] = useState(contextDavaData?.AddressValue);
  const [AddressList, setAddressList] = useState([]);
  const [DataKey, setDataKey] = useState(contextDavaData?.davadata[0]?.data?.adgangsadresseid);
  const [SelectedDate, setSelectedDate] = useState(contextDavaData?.date ? contextDavaData.date: '');

  const addresFillHandel = () => {
    addressRef.current.focus();
  }
  const handleNextClick = () => {
	if(SelectedDate===''){
		var dateInput = document.getElementById('selected_Date');
		dateInput.focus();
	}else{ 
 		if(DataKey && DataKey != '' ){ 
      if(Area != 'FAIR Produktion'){
		  //need to reverse in case if need this step once again
         //handleNextPrevClick(3, data);//Old logic
         handleNextPrevClick(4, data);
      }else{
         handleNextPrevClick(4, data);
      }
		}else{ 
			addresFillHandel();
		}
	}
  }
  async function changePower(value) {
	setPowerDelivered(value);
	setSelectedDate('');
 	if(value === 'move_in'){
		setDate1(new Date());		
	}else{
		if(date2.getDate() <= 14){
			setDate1(new Date(date2.getFullYear(), date2.getMonth() + 1, 1));
		} else{
			setDate1(new Date(date2.getFullYear(), date2.getMonth() + 2, 1));
		}
	}
  }
   useEffect(() => {
    changePower(contextDavaData?.PowerDelivered ? contextDavaData?.PowerDelivered : 'SKIFT til');
   },[]);

  const getDavaHandler = async (e, key, item) => {
		document.getElementById('AddressField').value = e;
	  if(key==1 && item?.data.id != 'undefined' && item?.data.id != null){
			let mPayload = [];
			mPayload.push(item);
			contextDavaData.fetchDavadata(mPayload);
			setAddressValue(item.data.forslagstekst);
			setDataKey(mPayload[0]?.data?.id);
			setAddressList([]);
			return;
	  }
    let payload = await DavaKeyApi(e);  
    if (payload && payload != '') {
        setAddressList(payload ? payload : []);
    } else {
      setAddressList([]);
    }
  }
  var data = {
    power_delivered: PowerDelivered,
    address: AddressValue,
    date: SelectedDate
  }

  return (
    <StepContainer md="12">
      <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
        <div
          style={{
            margin: '0 auto',
            borderRadius: '10px',
          }}
        >
          <FlexColumnContainer className="px-csm-0 p-0">
            <div
              style={{
                textAlign: 'center',
                padding: '0px 0 20px 0',
                fontWeight: 'bold',
              }}
            >
              <span className="roboto-bold">Hvor skal strømmen leveres til?</span>
            </div>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="g-4 col-lg-12 col-12 mb-3">
              <Col className="px-csm-0">
                <Form.Control
                  ref={addressRef}
                  type="text"
                  name="adresse"
                  id="AddressField"
                  placeholder="Adresse"
                  onChange={(e) => getDavaHandler(e.target.value, 0,null)}
                  defaultValue={contextDavaData?.AddressValue}
                />
                {AddressList && AddressList.length > 0 ?
                  <ListGroup>
                    {AddressList?.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        className="addressList text-start"
                        onClick={(e) => getDavaHandler(item.forslagstekst, 1,item)}>
                        {item.forslagstekst}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  : null}
              </Col>
            </Row>
            <Row xs={1} md={1} lg={2} className="g-4 col-lg-12 col-12">
              <Col className="px-csm-0">
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
					  {Area ==='FAIR Produktion'?(
                    <Card.Title className="mr-35 inter-font f-22"><strong>Aftale i forvejen</strong></Card.Title>):
					  (<Card.Title className="mr-35 inter-font f-22"><strong>SKIFT </strong>til Fairpris</Card.Title>)}
                    <input type="radio" name="power_delivered" aria-label="Area" className="area_radio position-absolute opacity-0" value={'SKIFT til'} onChange={(e) => changePower(e.target.value)} defaultChecked={PowerDelivered === 'SKIFT til' ? 'checked' : ''} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
					  {Area ==='FAIR Produktion'?(
						<li className="inter-font f-75">Vælg denne hvis du allerede har en produktionsaftale hos et andet selsksab</li>
                      ):(
						<li className="inter-font f-75">Vælg denne mulighed hvis du vil have strøm leveret på din <b>eksisterende</b> adresse og vil skifte til Fairpris EL</li>
					  )}
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="px-csm-0">
                <Card className="h-100">
                  <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
					  {Area ==='FAIR Produktion'?(
                    <Card.Title className="text-left mr-35 inter-font f-22"><strong>Har ingen aftale </strong></Card.Title>):(
					  <Card.Title className="text-left mr-35 inter-font f-22"><strong>FLYT </strong>til adressen</Card.Title>)}
                    <input type="radio" name="power_delivered" className="area_radio position-absolute opacity-0" onChange={(e) => changePower(e.target.value)} value={'move_in'} defaultChecked={PowerDelivered === 'move_in' ? 'checked' : ''} />
                    <div className="area_radio_btn">
                      <img src="../images/green-check.svg" alt="" />
                    </div>
                    <Col>
					  {Area ==='FAIR Produktion'?(
                      <li className="inter-font f-75">Vælg denne hvis du ikke har en produktionsaftale i forvejen</li>
					  ):(
                      <li className="inter-font f-75">Vælg denne mulighed hvis du ønsker at få leveret strøm til en bolig som du flytter til! </li>
					  )}
                    </Col>
                  </Card.Body>
                </Card>
              </Col>  
                 <>
                  <Col className="px-csm-0">
                    <DatePicker locale="da"
						showIcon
						id="selected_Date" 
						name="selected_Date" 
						toggleCalendarOnIconClick
						minDate = {date1}
						dateFormat="dd-MM-yyyy"
						onChange={(date) => {
							const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`; // Construct the date string in yyyy-MM-dd format
							setSelectedDate(formattedDate); 
						   }} 
                      className="form-control" 
                      defaultValue={SelectedDate}
                      selected={SelectedDate}
                      placeholderText={'Vælg dato'}
                    />
                  </Col>
                  <Col className="px-csm-0">
					  {PowerDelivered === 'move_in' ?
						<p className="text-start inter-font f-75">Hvornår skal din strøm aftale starte fra, det kan f.eks. være din indflytningsdato eller lidt efter</p>
					  :<p className="text-start inter-font f-75">Din strømaftale kan tidligst starte på denne dato. Vær opmærksom på opsigelsesperioder hos dit eksisterende selskab</p>
					  }
                  </Col>
                </> 
            </Row>
            <FlexColumnContainer className="col-lg-12 col-12 px-csm-0">
              <div className="col-md-12 col-12" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5 border-2 btn-outline-dark"
                  outline
                  onClick={() => handleNextPrevClick(1,data)}

                >
                  Tilbage
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  flat
                  circle={false}
                  className="float-end px-5 py-2 rounded-5"
                  onClick={() => handleNextClick()}
                >
                  Fortsæt!
                </CDBBtn>
              </div>
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </div>
    </StepContainer>
  );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default PowerDeliveredStep;