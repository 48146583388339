import axios from "axios";

const DavaKeyApi = async (params = [], url = '', method = 'get') => {
	var parameters = {
		method: method,
		url: `https://api.dataforsyningen.dk/autocomplete?q=${params}`,
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		data:params
	};
	
	try {
		var response = await axios(parameters);
		return response?.data;
	} catch (error) {
		return error;
	}
	
};

export default DavaKeyApi;