import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { CDBStepper, CDBStep } from "cdbreact";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

import ProductStep from "../components/steps/ProductStep";
import PowerDeliveredStep from "../components/steps/PowerDeliveredStep";
import GreenElectricityStep from "../components/steps/GreenElectricityStep";
import SolarCellElectricityStep from "../components/steps/SolarCellElectricityStep";
import FamilyInfoStep from "../components/steps/FamilyInfoStep";
import PaymentStep from "../components/steps/PaymentStep";
import CPRCheckStep from "../components/steps/CPRCheckStep";
import { usePrisContext } from "../context/AppContext";

function Payform() {
    const [active, setActive] = useState(1);
    const [validated, setValidated] = useState(false);

    const { contextDavaData } = usePrisContext();

    const handleNextPrevClick = (a, b) => {     
        if ((a === 2) || (a === 7) && b != "") {
            b.infoType = 2;
            contextDavaData.fetchDavadata(b);
        }
        if (a === 3 && b != "") {
            if (b?.address === '') {
                return false;
            }
            b.infoType = 3;
            contextDavaData.fetchDavadata(b);
        }
        if (a === 4 && b != "") {
            b.infoType = 4;
            contextDavaData.fetchDavadata(b);
        }
        if (a === 5 && b != "") {
            b.infoType = 5;
            contextDavaData.fetchDavadata(b);
        }
        if (a === 'payment') {
            b.infoType = 'payment';
            contextDavaData.fetchDavadata(b);
            return false;
        }
        setActive(a);
    };

    return (
        <Row className=''>
            <Col md={12} className="d-flex flex-column justify-content-between">
                <Header />
                <Form noValidate validated={validated}>
                    <CDBStepper direction="horizontal" className="main__steps p-0" steps={[]}>
                        <CDBStep
                            id={1}
                            icon="pencil-alt"
                            name="Product Step"
                            handleClick={() => handleNextPrevClick(1)}
                            active={active}
                            className={`opacity-0 d-none`}
                            component={<ProductStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                        <CDBStep
                            id={2}
                            icon="info-circle"
                            name="Power Delivered Step"
                            handleClick={() => handleNextPrevClick(2)}
                            active={active}
                            className={`opacity-0 d-none m-0`}
                            component={<PowerDeliveredStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                        <CDBStep
                            id={3}
                            icon="book-reader"
                            name="Green Electricity Step"
                            handleClick={() => handleNextPrevClick(3)}
                            active={active}
                            className={`opacity-0 d-none`}
                            component={<GreenElectricityStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                        <CDBStep
                            id={4}
                            icon="check"
                            name="Solar Cell Electricity Step"
                            handleClick={() => handleNextPrevClick(4)}
                            active={active}
                            className={`opacity-0 d-none`}
                            component={<SolarCellElectricityStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                        <CDBStep
                            id={5}
                            icon="check"
                            name="Family Info"
                            handleClick={() => handleNextPrevClick(5)}
                            active={active}
                            className={`opacity-0 d-none`}
                            component={<FamilyInfoStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                        <CDBStep
                            id={6}
                            icon="check"
                            name="Payment"
                            handleClick={() => handleNextPrevClick(6)}
                            active={active}
                            className={`opacity-0 d-none`}
                            component={<PaymentStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                        <CDBStep
                            id={7}
                            icon="check"
                            name="CPR Check"
                            handleClick={() => handleNextPrevClick(3)}
                            active={active}
                            className={`opacity-0 d-none`}
                            component={<CPRCheckStep handleNextPrevClick={handleNextPrevClick} />}
                        />
                    </CDBStepper>
                </Form>
                <Footer />
            </Col>
        </Row>
    )
}

export default Payform;