import axios from "axios";

const MainApi = async (params = [], url = "", token) => {
  var parameters = {
    method: "post",
    url: url,
    headers: {
      Authorization: `Bearer ${token ? token : process.env.REACT_APP_TOKEN}`,
      "Content-Type": "application/json",
      "x-cubs-dk-ns": "cubs-live",
    },
    data: params,
  };

  try {
    var response = await axios(parameters);
    return response;
  } catch (error) {
    return error;
  }
};

export default MainApi;
