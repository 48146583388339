import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import Col from 'react-bootstrap/Col'

export default function Footer() {
    return (
        <Navbar bg="white" data-bs-theme="white" className='footer csm-hidden'>
            <Container>
                <Nav className="me-auto mx-auto text-center">
                    <Nav.Link className="inter-font" target="_blank"  href="https://fairpris.dk/el/handelsbetingelser/">Handelsbetingelser</Nav.Link>
                    {/* <Nav.Link className="inter-font px-md-5 mx-md-4"  href="#">Cookiepolitik</Nav.Link> */}
                    <Nav.Link className="inter-font" target="_blank" href="https://fairpris.dk/el/privatlivspolitik/">Privatlivspolitik</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}