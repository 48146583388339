import { useEffect, useState,React } from 'react';
import './App.css';
import AuthToken from './api/getAuthToken';
import Payform  from "./pages/Payform"
import PaymentSuccess  from "./payment-success"
import PaymentFail  from "./payment-fail"
import { usePrisContext } from './context/AppContext';
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
const { contextDavaData } = usePrisContext();
const [loaddata, setLoaddata] = useState(true);

useEffect(() => {
  setLoaddata(false);
  async function fetchData() {
    const response = await AuthToken();
    if(response.status == 200){
       contextDavaData.fetchDavadata({token:response?.data?.access_token, infoType:'token'});
    }
  }
  fetchData();
}, [loaddata]);
  return (
  <div className="App col-12">
      <Router>
          <Routes>
            <Route path="/" element={<Payform />}></Route>
            <Route path="/payment-success"element={<PaymentSuccess />}></Route>
            <Route path="/payment-fail"element={<PaymentFail />}></Route>
          </Routes>
      </Router>
	  </div>
  ); 
}

export default App;
