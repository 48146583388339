import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col'

export default function Header() {
    return (
        <Navbar className="mt-5">
            <Container>
                <Col md={12}>
                    <img style={{ maxWidth: '100%' }} src="../images/logo.png" alt='logo' className='mb-3' />
                    <h2 className='text-black fw-bold roboto-bold mobile_hide_text'>Komplette energi løsninger i hele Danmark.</h2>
                    {/* TrustBox widget - Micro TrustScore */}

                    <div className="trustpilot-widget" data-locale="da-DK" data-template-id="5419b637fa0340045cd0c936" data-businessunit-id="63bbf6ba2f3a258c2386645f" data-style-height="20px" data-style-width="100%" data-theme="light">
                    <a href="https://dk.trustpilot.com/review/fairpris.dk" target="_blank" rel="noopener">Trustpilot</a>
                    </div>

                    {/* End TrustBox widget */}
                </Col>
            </Container>
        </Navbar>
    );
}