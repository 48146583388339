import React, { useEffect,useState } from "react";
import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import { CDBBtn } from "cdbreact";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { usePrisContext } from "../../context/AppContext"; 

function ProductStep({ handleNextPrevClick }) {
    const { contextDavaData } = usePrisContext();
    const [Area, setArea] = useState(contextDavaData?.Area ? contextDavaData?.Area : 'FAIR Flex');
 
 
    var productInfo = {
        product: Area
    }
    let triggerInput = (e, id) => {
        var clickBtn = document.getElementById(id);
        clickBtn.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            }));
        setArea(e);            
    }
    return (
        <StepContainer>
            <div className={`px-csm-0 p-0`} style={{ width: '100%', background: '#fff', height: '100%' }}>
                <div
                    style={{
                        margin: '0 auto',
                        borderRadius: '10px',
                    }}
                >
                    <FlexColumnContainer className="px-csm-0 p-0">
                        <div
                            style={{
                                textAlign: 'center',
                                padding: '0px 0 20px 0',
                                fontWeight: 'bold',
                            }}
                        >
                            <span className="roboto-bold">Vælg det produkt du foretrækker</span>
                        </div>
                        <Row xs={1} md={1} lg={3} className="g-4 col-lg-12 col-12">
                            <Col className="px-csm-0">
                                <Card className="h-100">
                                    <Card.Body className="position-relative d-flex flex-column areas_section " style={{ textAlign: 'left' }}>
                                        <Card.Title className="mr-35 inter-font f-26"><strong>FAIR</strong> Flex</Card.Title>
                                        <input id="fair-flex" type="radio" name="area" aria-label="Area" className="area_radio position-absolute opacity-0" value={'FAIR Flex'} onChange={(e) => setArea(e.target.value)} defaultChecked={Area === 'FAIR Flex' ? 'checked' : ''} />
                                        <div className="area_radio_btn">
                                            <img src="../images/green-check.svg" alt="" />
                                        </div>
                                        <Col>
                                            <Card.Text className="mt-3 mb-1 inter-font f-75">Variabel pris der følger markedsprisen time for time</Card.Text>
                                            <Card.Text className="mt-2 mb-3 inter-font f-75 highlight"><img src="../images/black-check.svg" alt="" />&nbsp; Gratis salg af overskudsstrøm</Card.Text>
                                            <Card.Text className="mt-2 mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Ingen binding</Card.Text>
                                            <Card.Text className="mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Månedlig betaling</Card.Text>
                                            <Card.Text className="mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; 0,- kr i abonnement</Card.Text>
                                            <Card.Text className="mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; 0,- kr i oprettelse</Card.Text>
                                            <Card.Text className="inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Kun 10 øre i spottillæg pr. kWh inkl. Moms</Card.Text>
                                        </Col>
                                        <Button variant="outline-dark" className="rounded-5 mt-3 col-12 position-relative z-2 roboto-bold fair-flex" onClick={(e) => triggerInput('FAIR Flex', 'fair-flex')} > Vælg FAIR Flex</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="px-csm-0">
                                <Card className="h-100">
                                    <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                                        <Card.Title className="text-left inter-font f-26"><strong>FAIR</strong> Fast</Card.Title>
                                        <input id="fair-fast" type="radio" name="area" aria-label="Area" className="area_radio position-absolute opacity-0" onChange={(e) => setArea(e.target.value)} value={'FAIR Fast'} defaultChecked={Area === 'FAIR Fast' ? 'checked' : ''} />
                                        <div className="area_radio_btn">
                                            <img src="../images/green-check.svg" alt="" />
                                        </div>
                                        <Col>
                                            <Card.Text className="mt-3 mb-1 inter-font f-75">Variabel pris der følger markedsprisen time for time. Køb strøm til ren spotpris.</Card.Text>
                                            <Card.Text className="mt-2 mb-3 inter-font f-75 highlight"><img src="../images/black-check.svg" alt="" />&nbsp; Gratis salg af overskudsstrøm</Card.Text>
                                            <Card.Text className="mt-2 mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Ingen binding</Card.Text>
                                            <Card.Text className="mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Månedlig betaling</Card.Text>
                                            <Card.Text className="mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; 0,- kr i oprettelse</Card.Text>
                                            <Card.Text className="mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; 0,- kr tillæg</Card.Text>
                                            <Card.Text className="inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Kun 39,- kr. I abonnement</Card.Text>
                                        </Col>
                                        <Button variant="outline-dark" className="rounded-5 mt-3 col-12 position-relative z-2 roboto-bold fair-fast" onClick={(e) => triggerInput('FAIR Fast', 'fair-fast')} >Vælg FAIR Fast</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="px-csm-0">
                                <Card className="h-100">
                                    <Card.Body className="position-relative d-flex flex-column areas_section" style={{ textAlign: 'left' }}>
                                        <Card.Title className="text-left inter-font f-26"><strong>FAIR</strong> Produktion</Card.Title>
                                        <input id="fair-produktion" type="radio" name="area" aria-label="Area" className="area_radio position-absolute opacity-0" onChange={(e) => setArea(e.target.value)} value={'FAIR Produktion'} defaultChecked={Area === 'FAIR Produktion' ? 'checked' : ''} />
                                        <div className="area_radio_btn">
                                            <img src="../images/green-check.svg" alt="" />
                                        </div>
                                        <Col>
                                            <Card.Text className="mt-3 mb-1 inter-font f-75">Hvis du er eksisterende kunde i Fairpris i forvejen men ønsker at oprette en produktionsaftale</Card.Text>
                                            <Card.Text className="mt-2 mb-3 inter-font f-75 highlight"><img src="../images/black-check.svg" alt="" />&nbsp;Gratis salg af overskudsstrøm</Card.Text>
                                            <Card.Text className="mt-2 mb-3 inter-font f-75"><img src="../images/black-check.svg" alt="" />&nbsp; Ingen binding</Card.Text>
                                        </Col>
                                        <Button variant="outline-dark" className="rounded-5 mt-3 col-12 position-relative z-2 roboto-bold fair-fast" onClick={(e) => triggerInput('FAIR Produktion', 'fair-produktion')} >Vælg FAIR Produktion</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <FlexColumnContainer className="col-lg-12 col-12 px-csm-0">
                            <div style={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                                <CDBBtn
                                    color="dark"
                                    flat
                                    circle={false}
                                    className="float-end px-5 py-2 rounded-5"
                                    onClick={() => handleNextPrevClick(productInfo.product != 'FAIR Produktion' ? 2 : 7, productInfo)}
                                >
                                    Fortsæt
                                </CDBBtn>
                            </div>
                        </FlexColumnContainer>
                    </FlexColumnContainer>
                </div>
            </div>
        </StepContainer>
    );
};

const FlexColumnContainer = styled('div')`
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    box-sizing: border-box;
  `;
const StepContainer = styled('div')`
    width: 100%;
    height: 100%;
  `;

export default ProductStep;
